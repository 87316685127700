:root {
  /* font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif; */
  line-height: 1.7;
  font-size: 1rem;
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --mainbg: #1e202f;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

input:focus,
input:active {
  box-shadow: none !important;
}

a {
  text-decoration: none;
}

* {
  transition: all 100ms ease-in-out;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden !important;
  background-color: #fff;
}

.bks:hover, .bks-first{
  color: white !important;
}

.bks:hover .bks1,
.bks:hover .bks2,
.bks:hover .bks3,
.bks:hover .bks4
{
  background: white !important;
}
.bks1{
  background: rgba(232, 148, 148, 0.519);
}
.bks2{
  background: rgba(232, 198, 148, 0.519);
}
.bks3{
  background: rgba(155, 232, 148, 0.519);
}
.bks4{
  background: rgba(148, 196, 232, 0.519);
}
.bks:hover, .bks-first {
  background-color: #171828;
}
.topshad {
  box-shadow: 1px -2px 5px rgba(240, 239, 239, 0.65);
}

.scrollsdown::-webkit-scrollbar {
  height: 0px;
}

.scrolls::-webkit-scrollbar {
  width: 5px;
}

.scrolls::-webkit-scrollbar-thumb {
  width: 5px;
  background: lightgrey;
  border-radius: 10rem;
}
.bgpos {
  background-position: 0rem 10rem;
}

.bgs {
  background: url(./assets/main-images/imgs.jpg)center/cover;
}
.news-card {
  background: url(./assets/images/news.jpg)center bottom/cover;
}

.bg-card {
  background: url(./assets/images/card.jpg)center/cover;
}

.wavy {
  background: url(./assets/main-images/wavy.png)center/cover no-repeat;
}

.bg-card2 {
  background: url(./assets/images/card2.jpg)center/cover;
}

.bg-card3 {
  background: url(./assets/main-images/loadsbg.gif)center/cover;
}
.shs {
  background: url(./assets/main-images/shs.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.bgkyc {
  background: url(./assets/images/img9.png);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.bg-card3-sub {
  background: -webkit-linear-gradient(bottom, #fff, #f57f1788);
}
.whatwedo {
  background: url(./assets/main-images/img1.jpeg)center/cover;
}
.bgbg {
  background: url(./assets/main-images/bg.png)center/cover no-repeat;
}
.mainbg {
  /* background: -webkit-linear-gradient(left, #171828, #171828, #2d305b) */
  background-color: var(--mainbg);
}
.mainbgside {
  /* background: -webkit-linear-gradient(bottom, #171828, #171828, #2d305b) */
  background-color: var(--mainbg);
}
.lefts {
  animation: lefts 300ms;
}
@keyframes lefts {
  from {transform: translateX(-30rem);opacity: .4;}
  to {transform: translateX(0);opacity: 1;}
}

.input {
  width: 100%;
  padding: 1.1rem 1rem;
  border: 1px solid lightgrey;
  outline: none;
  background: transparent;
  border-radius: 0.3rem;
  font-size: 0.8rem;
}

.inputselect {
  width: 100%;
  border: 1px solid lightgrey;
  padding: 1.2rem 0;
  outline: none;
  background: transparent;
  border-radius: 0.3rem;
  font-size: 0.8rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}


.input:focus,
.input:active,
.inputselect:focus,
.inputselect:active{
  outline: none;
  border: 1px solid lightgrey;
}

.bg-team {
  background: url(./assets/main-images/img1.jpeg)center/cover no-repeat;
}


.firmdiv {
  height: 0;
  transition: height 200ms ease-in-out;
}

.firmtag:hover .firmdiv {
  height: 32rem;
}

.abt:hover .abt-line {
  width: 16rem;
}

.tmtag {
  display: none;
  transition: display 100ms ease-in-out;
}

.tm:hover .tmtag {
  display: flex;
  animation: tmtag 300ms;
}

.ani {
  animation: ping 300ms;
}

@keyframes ping {
  from {
    transform: translateY(-40rem);
  }
  to {
    transform: translateY(0rem);
  }
}
@keyframes tmtag {
  0% {
    opacity: .4;
    transform: translateY(5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 10px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}
.cursive {
  font-family: cursive;
}
.incoming::before {
  content: '';
  position: absolute;
  top: 0;
  left: -0.8rem;
  border-style: solid;
  border-width: 0.6rem;
  border-color: white white transparent transparent;
}
.outgoing::before {
  content: '';
  position: absolute;
  top: 0;
  right: -0.8rem;
  border-style: solid;
  border-width: 0.6rem;
  border-color: #e6f4f6 transparent transparent #e6f4f6;
}

.foil {
  animation: foil 500ms;
}
@keyframes foil {
  from {
    transform: translateY(-5rem);
    opacity: 0.3;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.chatfoil {
  animation: chatfoil 300ms;
}
@keyframes chatfoil {
  from {
    transform: translateY(3rem);
    opacity: 0.3;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}
/* HTML: <div class="loader"></div> */
.sloader {
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  border: 3px solid #0000;
  border-radius: 50%;
  border-color: #ffffffb0 #0000;
  animation: l16 1s infinite linear;
}
.sloader::before,
.sloader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.sloader::before {
  border-color: #33f03f #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.lsoader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}

.anti-clockwise {
  animation: anti-clockwise 1000ms;
}
@keyframes anti-clockwise {
  to {
    transform: rotate(-360deg);
  }
}

.contacts {
  background: url(./assets//main-images/contact.svg)center/cover no-repeat;
  width: 100%;
}

.dloader {
  position: relative;
  width: 75px;
  height: 30px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#FFF 50px, transparent 0),
                    linear-gradient(#FFF 50px, transparent 0),
                    linear-gradient(#FFF 50px, transparent 0),
                    linear-gradient(#FFF 50px, transparent 0),
                    linear-gradient(#FFF 50px, transparent 0),
                    linear-gradient(#FFF 50px, transparent 0);
  background-position: 0px center, 15px center, 30px center, 45px center, 60px center, 75px center, 90px center;
  animation: rikSpikeRoll 1s linear infinite alternate;
}
@keyframes rikSpikeRoll {
0% { background-size: 10px 3px;}
16% { background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
33% { background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
50% { background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px}
66% { background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px}
83% { background-size: 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px, 10px 3px}
100% { background-size: 10px 3px, 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px}
}
  
.grad {
  background: radial-gradient(#1e202f, #018bb5, #2e61e6, #f44236, #f44236);
  /* background: radial-gradient(#1e202f, #1e202f, #2e61e6, #f44236, #f44236); */
  background-clip: text;
  color: transparent;
}

.card-label {
  position: relative;
}
.card-label::after {
  content: '';
  position: absolute;
  bottom: -2rem;
  left: 0;
  border-style: solid;
  border-width: 1rem;
  border-color: black black transparent transparent;
}

.card-label::before {
  content: '';
  position: absolute;
  top: -2rem;
  right: 0;
  border-style: solid;
  border-width: 1rem;
  border-color: transparent transparent black black;
}